html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'Roboto', Helvetica, sans-serif;
  background-color: $backgound-color;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: $font-size * 0.875;

  line-height: 1.625;
  color: $color;

  @media only screen and (min-width: $screen-sm) {
    font-size: $font-size;
  }
}

html,
body {
  min-height: 100vh;
  //height: 100%;
}
