h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
  color: $secondary-color;
}

h2 {
  font-family: 'Roboto Condensed', Helvetica, sans-serif;
  @include fontSize(28px);
  line-height: 1.2em;

  @media only screen and (min-width: $screen-sm) {
    @include fontSize(52px);

    &.small {
      @include fontSize(40px);
    }
  }
}

h3 {
  @include fontSize(24px);
  color: #fff;
  background-color: $secondary-color;
  width: 100%;
  padding: 0.25em 1em 0.333334em;
  line-height: 1.333334em;
  text-align: center;
}
h4 {
}
h5 {
  font-size: 1em;
  color: $secondary-color;
  font-weight: 700;
}
h6 {
}

p {
  margin: 0 0 1.625rem;
}

ul {
  margin: 0 0 1.625rem;
  padding-left: 1em;
}

p + ul {
  margin-top: -15px;
}

em {
  font-style: normal;
  font-weight: 600;
  color: $secondary-color;
}

a {
  color: $color;

  text-decoration: underline;
}
