.container {
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;

  width: 100%;


  @media only screen and (min-width: $screen-sm) {
    //width: $container-sm;
  }
  @media only screen and (min-width: $screen-md) {
    width: $container-md;
  }
  @media only screen and (min-width: $screen-lg) {
    width: $container-lg;
  }
}


.section--highlights {
  @media only screen and (min-width: $screen-sm) {
    .container {
      width: 36.875rem;
    }
  }
  @media only screen and (min-width: $screen-md) {
    .container {
//      width: $container-md;
    }
  }
  @media only screen and (min-width: $screen-lg) {
    .container {
      width: $container-lg;
    }
  }
}
