//#page-container
.section--article {
  &.section--article__fillbackground {
    min-height: 100vh;
  }

  background-position: 50% top;
  background-repeat: no-repeat;
  //background-size: cover;
  background-color: $backgound-color;
  background-attachment: inherit;
  background-size: contain;

  .impressum & {
    background-image: url('../images/background/589/datenschutz_impressum.jpg');
  }
  .eventmoderation & {
    background-image: url('../images/background/589/eventmoderation.jpg');
  }
  .home & {
    background-image: url('../images/background/589/home.jpg');
    background-size: cover;
  }
  .kontakt & {
    background-image: url('../images/background/589/kontakt.jpg');
  }
  .medientraining & {
    background-image: url('../images/background/589/medientraining.jpg');
  }
  .showreel & {
    background-image: url('../images/background/589/showreel.jpg');
  }
  .vita & {
    background-image: url('../images/background/589/vita.jpg');
  }

  @media only screen and (min-width: $screen-sm) {
    background-size: auto;
    .impressum & {
      background-image: url('../images/background/1487/datenschutz_impressum.jpg');
    }
    .eventmoderation & {
      background-image: url('../images/background/1487/eventmoderation.jpg');
    }
    .home & {
      background-image: url('../images/background/1487/home.jpg');
      background-size: auto;
    }
    .kontakt & {
      background-image: url('../images/background/1487/kontakt.jpg');
    }
    .medientraining & {
      background-image: url('../images/background/1487/medientraining.jpg');
    }
    .showreel & {
      background-image: url('../images/background/1487/showreel.jpg');
    }
    .vita & {
      background-image: url('../images/background/1487/vita.jpg');
    }
  }
  @media only screen and (min-width: $screen-md) {
    background-position: 50% top;
    background-repeat: no-repeat;
    //background-size: cover;
    background-color: $backgound-color;
    background-attachment: inherit;

    .impressum & {
      background-image: url('../images/background/1920/datenschutz_impressum.jpg');
    }
    .eventmoderation & {
      background-image: url('../images/background/1920/eventmoderation.jpg');
    }
    .home & {
      background-image: url('../images/background/1920/home.jpg');
    }
    .kontakt & {
      background-image: url('../images/background/1920/kontakt.jpg');
    }
    .medientraining & {
      background-image: url('../images/background/1920/medientraining.jpg');
    }
    .showreel & {
      background-image: url('../images/background/1920/showreel.jpg');
    }
    .vita & {
      background-image: url('../images/background/1920/vita.jpg');
    }
  }
}

.container--top {
  margin-top: 60vw;
  align-items: flex-start;

  @media only screen and (min-width: $screen-sm) {
    margin-top: 490px;
  }
  @media only screen and (min-width: $screen-md) {
    margin-top: auto;
    align-items: center;
  }
}

.home {
  .container--top {
    margin-top: 10vw;

    @media only screen and (min-width: $screen-sm) {
      margin-top: 10vw;
    }
    @media only screen and (min-width: $screen-md) {
      margin-top: auto;
      align-items: center;
    }
  }
}

.section--article {
  height: 100%;

  @media only screen and (min-width: $screen-sm) {
    height: 100%;
  }
  @media only screen and (min-width: $screen-md) {
    height: 100vh;
  }
}

.backstretch {
  @media only screen and (min-width: $screen-md) {
    display: none;
  }
}
