// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.

// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark: #373a3c !default;
$gray: #55595c !default;
$gray-light: #818a91 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;

$brand-primary: #0275d8 !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: false !default;
$enable-hover-media-query: false !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$border-width: 0.0625rem !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff !default;
$body-color: $gray-dark !default;

// Links
//
// Style anchor elements.

$link-color: $brand-primary !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 0;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
//$screen-sm:                  48em; // 768
$screen-sm: 36.875em; // 590
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 62em; // 992
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 75em; // 1200
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$grid-gutter-width: 2.5rem;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
//$container-tablet:             (45rem + $grid-gutter-width);
$container-tablet: (38.125rem + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (58.25rem + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (71.25rem + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

$color: #474749;
$backgound-color: #f1f1f1;
$font-size: 16px; // 1em

$secondary-color: #d40050;

$header-nav-color: #524442;
$header-nav-active-color: #d6175f;
