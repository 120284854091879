main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

.section--article {
  padding-top: 56px;
  height: 100vh;
  max-height: 1066px;

  @media only screen and (min-width: $screen-sm) {
    padding-top: 120px;
    height: 100vh;
  }

  @media only screen and (min-width: $screen-md) {
  }
}

section {
  position: relative;
}

.section--highlights {
  background-color: #f1f1f1;
  width: 100%;
  //height: 100px;
  //flex: 1;
}

.section--carousel {
  background-color: #f1f1f1;
  width: 100%;
  //height: 100px;
  //flex: 1;
}

.container--top {
  height: 100%;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  align-items: flex-end;

  @media only screen and (min-width: $screen-md) {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-direction: row;
    align-items: center;
  }

  .home & {
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    @media only screen and (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.container--highlights {
  padding: 33px 0 0;

  &:empty {
    display: none;
    padding: 0;
  }

  @media only screen and (min-width: $screen-md) {
    padding: 44px 0 0;
  }
}
.container--bottom {
  padding: 20px 0 0;

  &:empty {
    display: none;
    padding: 0;
  }
}

.section--last {
  .container {
    margin-bottom: 10em;
  }

  &:empty {
    .container {
      display: none;
      padding: 0;
      margin-bottom: 0;
    }
  }
}

.article {
  width: auto;

  .article__header {
    padding: 10px 20px 10px;
  }

  .article__content {
    padding: 16px 20px 10px;
  }

  .article__footer {
    color: $secondary-color;
    padding: 10px 20px 20px;
  }

  .home & {
    &.article--gallery {
      max-width: 584px;
      flex: 0 1 52%;
      picture {
        margin: 0.25rem;
        padding: 0;
        display: block;
        max-width: 100%;
      }
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
      }
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 0 1 50%;
      }
    }
  }

  @media only screen and (min-width: $screen-sm) {
    .article__header {
      padding: 20px 0 20px 40px;
    }

    .article__content {
      padding: 26px 0 10px 40px;
    }

    .article__footer {
      color: $secondary-color;
      padding: 20px 0 50px 40px;
    }
  }

  @media only screen and (min-width: $screen-md) {
  }

  @media only screen and (min-width: $screen-lg) {
    .article__header {
      padding: 20px 40px 20px;
    }

    .article__content {
      padding: 26px 40px 10px;
    }

    .article__footer {
      color: $secondary-color;
      padding: 20px 40px 50px;
    }
  }
}

.article--transparent {
  // h2,
  // h3,
  // h4,
  // p,
  // li {
  //   color: #fff;
  // }

  p,
  ul {
    line-height: 1.5em;
  }

  .home & {
    @media only screen and (max-width: $screen-sm-max) {
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        list-style: none;
        text-align: center;
      }
    }
  }

  .article__header {
    padding-left: 0;
    padding-right: 0;
  }

  .article__content {
    padding-left: 0;
    padding-right: 0;
  }

  .article__footer {
    color: $secondary-color;
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (min-width: $screen-sm) {
    h2,
    h3,
    h4,
    p,
    li {
    }

    p,
    ul {
      font-size: 1.5rem;
      line-height: 1.5em;
    }

    .article__header {
      padding-left: 0;
      padding-right: 0;
    }

    .article__content {
      padding-left: 0;
      padding-right: 0;
    }

    .article__footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media only screen and (min-width: $screen-md) {
    h2,
    h3,
    h4 {
      color: $secondary-color;
    }
    p,
    li {
      color: $color;
    }

    .article__header {
      padding-left: 0;
      padding-right: 0;
    }

    .article__content {
      padding-left: 0;
      padding-right: 0;
    }

    .article__footer {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media only screen and (min-width: $screen-lg) {
  }
}

.article--white {
  background-color: rgba(255, 255, 255, 0.8);

  margin-top: 2rem;
  margin-bottom: 2rem;

  header {
    background-color: rgb(255, 255, 255);
    padding: 20px 40px 20px;
  }

  @media only screen and (min-width: $screen-sm) {
    .article__header {
      padding: 20px 40px 20px;
    }

    .article__content {
      padding: 26px 40px 10px;
    }

    .article__footer {
      color: $secondary-color;
      padding: 20px 40px 50px;
    }
  }

  @media only screen and (min-width: $screen-md) {
  }
}

/*

.home {
  .container--top {
    justify-content: center;
    align-items: flex-end;

    .article {

         }

    h2 {
      //color: #fff;
    }

    ul {
      font-size: 1.5rem;
      //color: #fff;
    }
  }


  @media only screen and (min-width: $screen-md) {
    .container--top {
      justify-content: flex-end;
      align-items: center;
    }
  }
}


.eventmoderation {
  .section--article {
    height: 855px;
    min-height: auto;
  }

  .article {
    width: auto;
  }
}

.showreel {
  .section--article {
    min-height: 460px;
  }
}






aside {

}
*/

.eventmoderation {
  .section--article {
    max-height: 95vh;
  }

  .article {
    //max-width: 400px;
  }

  @media only screen and (min-width: $screen-md) {
    .section--article {
      //max-height: 1066px;
    }
  }

  @media only screen and (min-width: $screen-lg) {
    .article {
      //max-width: none;
    }
  }
}

.eventmoderation,
.medientraining,
.vita,
.kontakt,
.impressum {
  .section--article {
    height: auto;
    max-height: none;
  }

  @media only screen and (min-width: $screen-sm) {
    .section--article {
      height: auto;
    }
  }

  @media only screen and (min-width: $screen-md) {
    .section--article {
      max-height: 1066px;
    }

    .article {
      width: 600px;
      margin: 2rem 0;
      //margin: 0;
    }
  }
}

.medientraining {
  @media only screen and (min-width: $screen-md) {
    .section--article {
      height: 100vh;
      min-height: 1000px;
    }

    .article {
      width: 690px;
    }
  }
}

.eventmoderation {
  @media only screen and (min-width: $screen-md) {
    .section--article {
      height: 100vh;
      min-height: 750px;
    }
  }
}

.showreel {
  #yt_player {
    max-width: 100%;
  }
  @media only screen and (min-width: $screen-sm) {
    .section--article {
      height: 100vh;
      min-height: 550px;
    }
  }
}

.vita {
  @media only screen and (min-width: $screen-sm) {
    .section--article {
      height: 100vh;
      min-height: 750px;
    }
  }
}

.impressum {
  @media only screen and (min-width: $screen-sm) {
    .section--article {
      //height: 100vh;
      height: auto;
    }
  }
  @media only screen and (min-width: $screen-md) {
    .section--article {
    }
    .article {
      width: 100%;
    }
    .article__content {
      overflow-y: scroll;
      max-height: 747px;
    }
  }
}

.kontakt {
  .section--article {
    height: auto;
    max-height: none;
  }

  .article__content {
    .logo--eimermacher {
      max-width: 75%;
    }
    .padded {
      padding-left: 32px;
    }
  }

  @media only screen and (min-width: $screen-sm) {
    .section--article {
      height: 100vh;
      min-height: 740px;
    }
  }

  @media only screen and (min-width: $screen-md) {
    .section--article {
      max-height: 1066px;
    }

    .article__content {
      .logo--eimermacher {
        max-width: 40%;
      }
      .padded {
        padding-left: 38px;
      }
    }
  }
}

.col-50 {
  @media only screen and (min-width: $screen-sm) {
    width: 50%;
    float: left;
    padding: 0 1rem 0 0;
  }
}

.logo--sprite {
  background-image: url(../images/eimermacher-sprite.png);
  background-position: 0 0;
  background-repeat: no-repeat;

  text-indent: -999rem;
  width: 50px;
  height: 50px;
  display: inline-block;
  padding-right: 1rem;
}

.logo--sprite--ntv {
  background-position: -240px 0;
  width: 100px;
  margin-right: 10px;
}

.logo--sprite--ntv--mobile {
  display: inline-block;

  @media only screen and (min-width: $screen-md) {
    display: none;
  }
}

.logo--sprite--ntv--desktop {
  display: none;

  @media only screen and (min-width: $screen-md) {
    display: inline-block;
  }
}

.logo--sprite--xing {
  background-position: -180px 0;
}

.text-center {
  text-align: center;
}

.scroll-btn-container {
  width: 100%;
}

.btn {
  text-decoration: none;
  position: relative;
  display: inline-block;

  &--scroll-down {
    font-size: 18px;
    line-height: 1em;
    color: $color;
    margin-top: 0%;
    margin-bottom: 20px;

    &:after {
      background-image: url(../images/eimermacher-sprite.png);
      background-position: -400px 0;
      background-repeat: no-repeat;

      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: relative;
      margin: 7px 50%;
      left: -20px;
    }
  }

  @media only screen and (min-width: $screen-md) {
    &--scroll-down {
      color: $color;

      &:after {
        background-position: -400px 0;
      }
    }
  }
}
