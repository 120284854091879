.carousel-container {
  background-color: #fff;
  padding: 20px 0;
  margin: 0 -20px;

  @media only screen and (min-width: $screen-md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.slider {
  //width: 870px;
  margin: 0 auto;
  padding: 0 135px;
}

.slick-slide img {
  display: inline-block;
}

.slick-initialized .slick-slide {
  text-align: center;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url('../images/slick-carousel/ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('../fonts/slick.eot');
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'),
    url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'),
    url('../fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
  background: url(../images/eimermacher-sprite.png) 0 0;

  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 50px;
  height: 50px;
  margin-top: -25px;
  padding: 0;

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  opacity: 0.8;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 29px;
  background-position: -60px 0;
}

.slick-next {
  right: 29px;
  background-position: -120px 0;
}
