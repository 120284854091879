.highlights {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 1.5625rem 0 0;

  @media only screen and (min-width: $screen-sm) {
    flex-direction: row;
    flex-wrap: wrap;
  }



  .highlights__element {
    margin-bottom: 1.25rem;
    text-decoration: none;
    position: relative;

    &:hover {
      .highlights__element__layover {
        position: absolute;
        background-color: $secondary-color;
        opacity: 0.8;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 60px;
          height: 60px;
          background-image: url(../images/eimermacher-sprite.png);
        }
      }
    }
  }

  .highlights__element__figure {
    margin: 0;
    background-color: #fff;

    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      @include fontSize(18px);
      color: $secondary-color;
      line-height: 1em;
      padding: 0.888889em 0.833333em 2.5em;
    }
  }
}
