#site-header {
  background-color: rgba(255,255,255,0.95);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @media only screen and (min-width: $screen-sm) {

  }
  @media only screen and (min-width: $screen-md) {
    background-color: rgba(255,255,255,0.8);
  }
}

header#site-header {
  .clearfix {
    position: relative;
  }

  a {
    text-decoration: none;
    color: $header-nav-color;
    display: inline-block;


    &:hover,
    &.active {
      color: $header-nav-active-color;
    }
  }
}

header#site-header {
  #site-logo {
    display: inline-block;
    background-image: url(../images/eimermacher-logo.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 150px auto;
    width: 150px;
    height: 36px;
    margin: 10px 0 10px;


    a {
      display: inherit;
      text-indent: -9999rem;
      width: inherit;
      height: inherit;
    }

    @media only screen and (min-width: $screen-sm) {
      width: 291px;
      height: 69px;
      margin: 25px 0 27px;
      background-size: auto;
    }

    // iPhone Landscape
    @media only screen and (min-width: $screen-sm)
    and (max-device-width : 736px)
    and (orientation : landscape) {
      width: 150px;
      height: 36px;
      margin: 10px 0 10px;
      background-size: 150px auto;
    }
  }

  .navbar-brand {
    margin: 20px 0 20px;
    width: 190px;

    @media only screen and (min-width: $screen-sm) {
      margin: 25px 0 27px;
      width: auto;
    }
  }
}

#site-header {
  nav.nav-top {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    display: none;

    @media only screen and (min-width: $screen-sm) {

    }

    @media only screen and (min-width: $screen-md) {
      display: block;
    }

    @media only screen and (min-width: $screen-lg) {

    }
  }
}

#site-header {
  #main-nav--container {
    //display: none;
    overflow: hidden;

    .mainnav--active & {
      //display: block;
    }

    @media only screen and (min-width: $screen-sm) {

    }
    @media only screen and (min-width: $screen-md) {
      display: block !important;
    }
  }

  nav.mainnav {
    font-size: 20px;
    line-height: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -110% 0 25px;
    transition: all .5s;

    .mainnav--active & {
      margin-top: 0;
    }

    a {
      padding: 0.4em 0;
    }

    @media only screen and (min-width: $screen-sm) {
      font-size: 22px;

      a {
        padding: 0.5em 0;
      }
    }
    @media only screen and (min-width: $screen-md) {
      flex-direction: row;
      flex-wrap: nowrap;
      position: absolute;
      bottom: 0;
      right: 0;

      width: 600px;

      .hide-on-desktop {
        display: none !important;
      }

      a {
        padding: 0;
      }
    }
    @media only screen and (min-width: $screen-lg) {
      width: 680px;
    }
  }
}


header#site-header {
  #navbar-toggler {
    position: absolute;
    margin: 3px 0 0px;
    right: 0;

    @media only screen and (min-width: $screen-sm) {
      margin: 33px 0 0;
    }
    // iPhone Landscape
    @media only screen and (min-width: $screen-sm)
    and (max-device-width : 736px)
    and (orientation : landscape) {
      margin: 3px 0 0px;
    }
    @media only screen and (min-width: $screen-md) {
      display: none !important;
    }
  }
}

/*
.navbar-nav {
  .nav-item + .nav-item {
    margin-left: 1.75rem;
  }
}

*/
