// http://sarasoueidan.com/demos/navicon-transformicons/
$button-size : 40px;
$button-line-height: 5px;
$button-color: #d40050;
$button-background-color: #fff;
$transition: .3s; // increase this to see the transformations in slow-motion

@mixin line {
  display: inline-block;
  width: $button-size;
  height: $button-line-height;
  background: $button-color;
  transition: $transition;
}

.hamburger-menu {
  position: absolute;

  display: inline-block;
  padding: 9px ($button-size/4/1.25) 11px;
  transition: .3s;
  cursor: pointer;
  user-select: none;
  background-color: $button-background-color;
  border: 0;

  &:active {
    transition: none;
    background: $button-background-color;
  }

  .hamburger-menu--lines {
    //create middle line
    @include line;
    position: relative;

    /*create the upper and lower lines as pseudo-elements of the middle line*/
    &:before, &:after {
      @include line;
      position: absolute;
      left:0;
      content: '';
      transform-origin: $button-size/14/1.5 center;
    }
    &:before { top: $button-size/2.6666667/1.25; }
    &:after { top: -$button-size/2.6666667/1.25; }
  }

  .mainnav--active & {
      .hamburger-menu--lines {
        /*hide the middle line*/
        background: transparent;

        /*overlay the lines by setting both their top values to 0*/
        &:before, &:after {
          transform-origin: 50% 50%;
          top: 0;
          width: $button-size;
        }

        // rotate the lines to form the x shape
        &:before {
          transform: rotate3d(0, 0, 1, 45deg);
        }
        &:after {
          transform: rotate3d(0, 0, 1, -45deg);
        }

    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .hamburger-menu {
    padding: 17px ($button-size/4) 22px;

    .hamburger-menu--lines {
      &:before, &:after {
        transform-origin: $button-size/14 center;
      }
      &:before {
        top: $button-size/2.6666667;
      }
      &:after {
        top: -$button-size/2.6666667;
      }
    }
  }
}

// iPhone Landscape
@media only screen and (min-width: $screen-sm)
and (max-device-width : 736px)
and (orientation : landscape) {
  .hamburger-menu {
    padding: 9px ($button-size/4/1.5) 11px;

    .hamburger-menu--lines {
      &:before, &:after {
        transform-origin: $button-size/14/1.5 center;
      }
      &:before {
        top: $button-size/2.6666667/1.5;
      }
      &:after {
        top: -$button-size/2.6666667/1.5;
      }
    }
  }
}
