$fancybox-image-url: '../images/fancybox/' !default;
@import '../../../node_modules/fancybox/dist/scss/jquery.fancybox.scss';

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('#{$fancybox-image-url}/fancybox_sprite.png');
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('#{$fancybox-image-url}/fancybox_loading.gif') center center no-repeat;
}
.fancybox-overlay {
  background: url('#{$fancybox-image-url}/fancybox_overlay.png');
}

.fancybox-nav {
  background: transparent url('#{$fancybox-image-url}/blank.gif'); /* helps IE */
}

.fancybox-title {
  font: 400 1.15rem/1.25em 'Roboto', Helvetica, sans-serif;
  text-align: center;
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('#{$fancybox-image-url}/fancybox_sprite@2x.png');
  }

  #fancybox-loading div {
    background-image: url('#{$fancybox-image-url}/fancybox_loading@2x.gif');
  }
}
